microflow.factory("microflowFac", [
  "$http",
  "authenFac",
  "$timeout",
  function($http, authenFac, $timeout) {
    var getAuthenHeader = function() {
      return new Promise(function(resolve, reject) {
        var localHeader = JSON.parse(localStorage.getItem("header"));
        if (localHeader === undefined || localHeader == null) {
          $timeout(function() {
            localHeader = JSON.parse(localStorage.getItem("header"));
            var headerObj = null;
            var header = { "Content-Type": undefined };

            if (localHeader != undefined) {
              headerObj = {};
              for (var key in localHeader) {
                eval(
                  "headerObj." + key + " = 'Bearer '+ localHeader." + key + ";"
                );
              }
            }
            if (headerObj != null) {
              header = Object.assign(header, headerObj);
            }
            resolve(header);
          }, 1000);
        } else {
          var headerObj = null;
          var header = { "Content-Type": undefined };
          if (localHeader != undefined) {
            headerObj = {};
            for (var key in localHeader) {
              eval(
                "headerObj." + key + " = 'Bearer '+ localHeader." + key + ";"
              );
            }
          }
          if (headerObj != null) {
            header = Object.assign(header, headerObj);
          }
          resolve(header);
        }
      });
    };
    var doFlowAction = function(data, scope) {
      return new Promise((resolve, reject) => {
        var headerObj = null;
        getAuthenHeader().then(function(inObj) {
          headerObj = inObj;
          if ($http.defaults.headers.common["Authorization"] == undefined) {
            $http.defaults.headers.common["Authorization"] =
              headerObj.Authorization;
          }
          $http({
            method: "POST",
            url: microflowREST,
            data: data
          }).then(
            function successCallback(response) {
              var objRes = response.data;
              if (objRes.responseStatus === "SUCCESS") {
                var objMapp = objRes.responseObjectsMap;
                var objFlowName = objRes.flowName;
                for (var key in objMapp) {
                  if (
                    key !== "iamHeader" &&
                    key !== "localStorage" &&
                    key != "permissionRole"
                  ) {
                    if (scope.Store[objFlowName] === undefined) {
                      scope.Store[objFlowName] = {};
                    }
                    scope.Store[objFlowName][key] = objMapp[key];
                  } else {
                    if (key === "iamHeader") {
                      localStorage.setItem(
                        "header",
                        JSON.stringify(objMapp[key])
                      );
                    } else if (key === "localStorage") {
                      localStorage.setItem(
                        "appStorage",
                        JSON.stringify(objMapp[key])
                      );
                    } else if (key === "permissionRole") {
                      var perms = objMapp[key].Permission;
                      if (perms !== undefined) {
                        var permRole = perms.role;
                        var permObjectAccesses = perms.objectAccesses;
                        var roles = [];
                        var objectAccesses = [];

                        for (var i = 0; i < permRole.length; i++) {
                          roles.push(permRole[i].role_name);
                        }
                        authenFac.userRoles = roles;
                        for (var i = 0; i < permObjectAccesses.length; i++) {
                          objectAccesses.push(permObjectAccesses[i]);
                        }
                        if (objectAccesses.length > 0) {
                          authenFac.isLogin = true;
                        }
                        authenFac.objectAccesses = objectAccesses;
                      }
                    }
                  }
                }
                scope.update();
                resolve(objRes);
              } else {
                reject(objRes);
              }
            },
            function errorCallback(response) {
              reject(response);
            }
          );
        });
      });
    };
    return {
      doFlowAction: doFlowAction
    };
  }
]);
